@import "styles/fonts";
@import "styles/scrollbars";
@import "styles/variables";
@import "node_modules/bootstrap/scss/bootstrap";

html,
body {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;

  font-family: Montserrat, sans-serif;
  font-size: 15px;

  @include media-breakpoint-down(md) {
    font-size: 3vmin;
  }
}

body {
  overflow: scroll;
}

h1 {
  font-size: 3.5rem;
}

.main-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  min-height: 100%;

  padding-top: 2rem;
  padding-bottom: 3rem;
}

.button-column .btn {
  width: 20rem;
  margin: 0.7rem;
}

.applications .btn {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 10rem;
  margin: 0.7rem;
}

.applications img {
  height: 1.1rem;
  margin-right: 0.7rem;
}

.made-by-brain-new {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  font-size: 0.9rem;

  margin-top: 2rem;
}
