// Variables
//
// Variables should follow the `$component-state-property-size` formula for
// consistent naming. Ex: $nav-link-disabled-color and $modal-content-box-shadow-xs.

// Fonts
$font-family-sans-serif: "Montserrat" !default;
$font-family-monospace: $font-family-sans-serif !default;
$font-family-base: $font-family-sans-serif !default;

$font-size-base: 1rem !default; // Assumes the browser default, typically `16px`
$font-size-lg: $font-size-base !default;

$font-weight-lighter: lighter !default;
$font-weight-light: 300 !default;
$font-weight-normal: 400 !default;
$font-weight-bold: 700 !default;
$font-weight-bolder: bolder !default;

$font-weight-base: $font-weight-normal !default;

// Color system
$white: #fff !default;
$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #6c757d !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;
$black: #000 !default;

$blue: #007bff !default;
$indigo: #6610f2 !default;
$purple: #6f42c1 !default;
$pink: #e83e8c !default;
$red: #dc3545 !default;
$orange: #fd7e14 !default;
$yellow: #ffc107 !default;
$green: #28a745 !default;
$teal: #20c997 !default;
$cyan: #17a2b8 !default;

$primary: #00a699 !default;
$secondary: #457b9d !default;
$success: #0da600 !default;
$info: #008aa6 !default;
$warning: #fc642d !default;
$danger: #ff5a5f !default;
$light: $gray-100 !default;
$dark: $gray-800 !default;

$theme-colors: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$theme-colors: map-merge(
  (
    "primary": $primary,
    "secondary": $secondary,
    "success": $success,
    "info": $info,
    "warning": $warning,
    "danger": $danger,
    "light": $light,
    "dark": $dark,
  ),
  $theme-colors
);

// Options
//
// Quickly modify global styling by enabling or disabling optional features.

$enable-caret: true !default;
$enable-rounded: true !default;
$enable-shadows: true !default;
$enable-gradients: false !default;
$enable-transitions: true !default;
$enable-prefers-reduced-motion-media-query: true !default;
$enable-grid-classes: true !default;
$enable-pointer-cursor-for-buttons: true !default;
$enable-print-styles: true !default;
$enable-responsive-font-sizes: true !default;
$enable-validation-icons: true !default;
$enable-deprecation-messages: true !default;

// Grid breakpoints
//
// Define the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
) !default;

// These variables are helpers for media queries
$large-display: (
  min-width: map_get($grid-breakpoints, "lg"),
);

// Components
//
// Define common padding and border radius sizes and more.
$border-width: 1px !default;
$border-color: $gray-300 !default;

$border-radius: 12px !default;
$border-radius-lg: 14px !default;
$border-radius-sm: 10px !default;

$box-shadow: rgba(0, 0, 0, 0.33) 0px 2px 10px !default;
$box-shadow-sm: rgba(0, 0, 0, 0.33) 0px 2px 8px !default;
$box-shadow-lg: rgba(0, 0, 0, 0.33) 0px 2px 12px !default;

// Input
$input-btn-padding-y: 0.625rem !default;
$input-btn-padding-x: 1.25rem !default;

$custom-select-padding-y: $input-btn-padding-y !default;
$custom-select-padding-x: $input-btn-padding-x !default;
$custom-select-border-radius: $border-radius !default;

// Buttons
//
// For each of Bootstrap's buttons, define text, background, and border color.

$btn-box-shadow: none !default;
$btn-focus-box-shadow: none !default;
$btn-active-box-shadow: none !default;

// Allows for customizing button radius independently from global border radius
$btn-border-radius: 8px !default;
$btn-border-radius-lg: $btn-border-radius !default;

$btn-padding-y: $input-btn-padding-y !default;
$btn-padding-x: $input-btn-padding-x !default;
$btn-padding-y-lg: $btn-padding-y !default;
$btn-padding-x-lg: $btn-padding-x !default;

$btn-font-size: $font-size-base !default;
$btn-font-size-lg: $btn-font-size !default;

$btn-font-weight: 500 !default;
